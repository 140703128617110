body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
}

html {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
}

* {
    box-sizing: border-box;
}
